
<template>
  <div class="login" style="margin-top:100px;">
    <s-header name="Reset you password" :back="'/home'"></s-header>


    <div class="login-body register">
      <van-form @submit="onSubmit">

        <van-field v-model="username1" name="username1" label="Username" placeholder="username"
          :rules="[{ required: true, 
            
            pattern: /^\d{8,20}$/,
            message: 'Please enter correct phone number' }]" />


        <van-field v-model="email" name="email" label="Email" placeholder="Email" :rules="[{
          required: true,
          pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          message: 'please enter a correct email address'
        }]" />


        <van-field v-model="password1" type="password" name="password1" label="New Password" placeholder="password"
          :rules="[{ required: true, 
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
            message: 'the password consists of 8 to 16 characters, including numbers, lowercase letters, uppercase letters and special symbols'}]"
            
            />
        <div class="verify">
          <Verify ref="loginVerifyRef" @error="error" :showButton="false" @success="success" :width="'100%'"
            :height="'40px'" :fontSize="'16px'" :type="0"></Verify>

        </div>


        <div style="margin: 16px;">
          <van-button round block type="info" color="#1baeae" native-type="submit">Submit</van-button>
        </div>
      </van-form>
    </div>



    <div class="good" :style="{ paddingBottom: '90px' }">
      <div style="text-align: center;font-size: 12px;margin-top: 300px;">
        <p>
          © 2023 kuyakan Technology Co.,Limited - All Rights Reserved
        </p>
        <p>
          contac us,send an email to service@kuyakan.com
        </p>
      </div>
    </div>

   
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { login, loginForget, register, getUserInfo } from '../service/user'
import { setLocal, getLocal } from '@/common/js/utils'
import { Toast } from 'vant'
import Verify from 'vue2-verify'
export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      username1: '',
      password1: '',
      type: 'login',
      verify: false
    }
  },
  components: {
    sHeader,
    Verify
  },
  methods: {
    dealTriVer() {
      // 执行验证码的验证，通过 this.verify 知道验证码是否填写正确
      this.$refs.loginVerifyRef.$refs.instance.checkCode()
    },
    toggle(v) {
      this.verify = false
      this.type = v
    },
    async onSubmit(values) {
      //this.dealTriVer()
      // if (!this.verify) {
      //  Toast.fail('code is error!')
      // return
      //}
      const { data } = await loginForget({
        "loginName": values.username1,
        "userEmail": values.email,
        "passwordMd5": values.password1
      })
      Toast.success('sucess')
      //this.type = 'login'
      window.location.href = '/'
    },
    success(obj) {
      this.verify = true
      // 回调之后，刷新验证码
      obj.refresh()
    },
    error(obj) {
      this.verify = false
      // 回调之后，刷新验证码
      obj.refresh()
    }
  },
}
</script>

<style lang="less">
.login {
  .logo {
    width: 120px;
    height: 120px;
    display: block;
    margin: 80px auto 0px;
  }

  .login-body {
    padding: 0 20px;
  }

  .login {
    .link-register {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }

  .register {
    .link-login {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }

  .verify-bar-area {
    margin-top: 24px;

    .verify-left-bar {
      border-color: #1baeae;
    }

    .verify-move-block {
      background-color: #1baeae;
      color: #fff;
    }
  }

  .verify {
    >div {
      width: 100%;
    }

    display: flex;
    justify-content: center;

    .cerify-code-panel {
      margin-top: 16px;
    }

    .verify-code {
      width: 40% !important;
      float: left !important;
    }

    .verify-code-area {
      float: left !important;
      width: 54% !important;
      margin-left: 14px !important;

      .varify-input-code {
        width: 90px;
        height: 38px !important;
        border: 1px solid #e9e9e9;
        padding-left: 10px;
        font-size: 16px;
      }

      .verify-change-area {
        line-height: 44px;
      }
    }
  }
}
</style>
